import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "../util/asyncComponent";

const App = ({match}) => (
    <div className="">
        <Switch>
            <Route path={`${match.url}/`} component={asyncComponent(() => import('./Home'))}/>
            <Route path={`${match.url}home`} component={asyncComponent(() => import('./Home'))}/>
            <Route path={`${match.url}pol-votes`} component={asyncComponent(() => import('./Home/PolVote'))}/>
            <Route path={`${match.url}retreat-partner-selection`} component={asyncComponent(() => import('./Home/RetreatSelection'))}/>
            <Route path={`${match.url}my-profile`} component={asyncComponent(() => import('./SelfService'))}/>
            <Route path={`${match.url}leave-tracker`} component={asyncComponent(() => import('./LeaveTracker'))}/>
            <Route path={`${match.url}paystubs`} component={asyncComponent(() => import('./Paystubs'))}/>
            <Route path={`${match.url}attendance`} component={asyncComponent(() => import('./Attendance'))}/>
            <Route path={`${match.url}employeevalidation`} component={asyncComponent(() => import('./EmployeeValidation'))}/>
            <Route path={`${match.url}validationreports`} component={asyncComponent(() => import('./ValidationReports'))}/>
            <Route path={`${match.url}documents`} component={asyncComponent(() => import('./Documents'))}/>
            <Route path={`${match.url}query`} component={asyncComponent(() => import('./EmployeeQueries'))}/>
            <Route path={`${match.url}training`} component={asyncComponent(() => import('./Training'))}/>
            <Route path={`${match.url}announcements`} component={asyncComponent(() => import('./Announcements'))}/>
            <Route path={`${match.url}exits`} component={asyncComponent(() => import('./EmployeeExits'))}/>
            <Route path={`${match.url}events`} component={asyncComponent(() => import('./Events'))}/>
          
            <Route path={`${match.url}appraisals/details/:slug`}
                   component={asyncComponent(() => import('./Appraisals/AppraisalDetails'))}/>
              <Route path={`${match.url}appraisals/:slug/:user_id`}
                   component={asyncComponent(() => import('./Appraisals/AppraisalDetails/AppraisalSubmissions'))}/>
            <Route path={`${match.url}appraisals`} component={asyncComponent(() => import('./Appraisals'))}/>
            
             
            <Route path={`${match.url}feedback/:slug`} component={asyncComponent(() => import('./Events/EventFeedback'))}/>
        </Switch>
    </div>
);

export default App;
