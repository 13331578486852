import React, {useEffect} from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";


import UserProfile from "./UserProfile";

import {useSelector} from "react-redux";
import {HomeFilled,} from "@ant-design/icons";
import {
    FaClock,
    FaChild,
    FaBriefcase,
    FaQuestion,
    FaVolumeUp,
    FaFolder,
    FaDoorOpen,
    FaQuestionCircle,
    FaBookReader, FaBook, FaCheck, FaSmileWink
} from "react-icons/fa";
import { MAIN_MENU } from "../../constants/ServerUrl";


const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const SidebarContent = ({sidebarCollapsed, setSidebarCollapsed}) => {

    let {navStyle, themeType} = useSelector(({settings}) => settings);
    let {pathname} = useSelector(({common}) => common);
    let {authUser} = useSelector(({auth}) => auth);


    let selectedKeys = pathname.substr(1);
    let defaultOpenKeys = selectedKeys.split('/')[1];
    // const defaultOpenKeys = selectedKeys.split('/')[1];

    return (
        <>


            <div className="flex items-center justify-center py-2 px-2">
                <UserProfile/>
                {/*<AppsNavigation/>*/}
            </div>


            <Menu
                className="bg-blue text-white text-center text-base font-medium my-5"
                mode="inline"
                defaultOpenKeys={[defaultOpenKeys]}
                selectedKeys={[selectedKeys]}>
                {authUser && (


                    <MenuItemGroup key="dashboardGroup" className="mt-5">
                        <Menu.Item key="home">
                            {/* <Link to="/home" className="flex">
                                <div className="text-2xl">
                                    <HomeFilled/>
                                </div>
                                <span className="mx-4 py-1">Home</span>
                            </Link> */}
                            <div  className="flex mt-5"  onClick={() => {
                                    window.location.href = MAIN_MENU
                            }}>
                                <div className="text-2xl">
                                    <HomeFilled/>
                                </div>
                                <span className="mx-3">Home</span>
                            </div>
                        </Menu.Item>
                        <Menu.Item key="dashboard">
                            <Link to="/home" className="flex mt-10">
                                <div className="text-2xl">
                                    <HomeFilled/>
                                </div>
                                <span className="mx-4 py-1">Dashboard</span>
                            </Link>
                        </Menu.Item>
                        {authUser.status >= 4 && (
                            <Menu.Item key="my-profile">
                                <Link to="/my-profile" className="flex mt-10">
                                    <div className="text-2xl -ml-1 mt-3">
                                        <FaChild/>
                                    </div>
                                    <span className="mx-5 py-1">My Profile</span>
                                </Link>
                            </Menu.Item>)}
                        {authUser.status >= 4 && (

                            <Menu.Item key="announcements">
                                <Link to="/announcements" className="flex mt-10">
                                    <div className="text-2xl -ml-1 mt-3">
                                        <FaVolumeUp/>
                                    </div>
                                    <span className="mx-5 py-1">Announcements</span>
                                </Link>
                            </Menu.Item>)}
                        {authUser.status >= 4 && (

                            <Menu.Item key="attendance">
                                <Link to="/attendance" className="flex mt-10">
                                    <div className="text-2xl -ml-1 mt-3">
                                        <FaClock/>
                                    </div>
                                    <span className="mx-5 py-1">Attendance</span>
                                </Link>
                            </Menu.Item>)}


                        {authUser.status >= 4 && (

                            <Menu.Item key="documents">
                                <Link to="/documents" className="flex mt-10">
                                    <div className="text-2xl -ml-1 mt-3">
                                        <FaFolder/>
                                    </div>
                                    <span className="mx-5 py-1">Documents</span>
                                </Link>
                            </Menu.Item>)}

                        {authUser.status >= 4 && (
                            <Menu.Item key="enquiries">
                                <Link to="/enquiries" className="flex mt-10">
                                    <div className="text-2xl -ml-1 mt-3">
                                        <FaQuestion/>
                                    </div>
                                    <span className="mx-5 py-1">Enquiries</span>
                                </Link>
                            </Menu.Item>)}

                        {authUser.status >= 4 && (
                            <Menu.Item key="events">
                                <Link to="/events" className="flex mt-10">
                                    <div className="text-2xl -ml-1 mt-3">
                                        <FaSmileWink/>
                                    </div>
                                    <span className="mx-5 py-1">Survey</span>
                                </Link>
                            </Menu.Item>)}
                            
                            <Menu.Item key="EmployeeValidation">
                                <Link to="/employeevalidation" className="flex mt-10">
                                    <div className="text-2xl -ml-1 mt-3">
                                        <FaSmileWink/>
                                    </div>
                                    <span className="mx-5 py-1">Employee Validation</span>
                                </Link>
                            </Menu.Item>
                        {authUser.status >= 4 && (
                            <Menu.Item key="appraisals">
                                <Link to="/appraisals" className="flex mt-10">
                                    <div className="text-2xl -ml-1 mt-3">
                                        <FaSmileWink/>
                                    </div>
                                    <span className="mx-5 py-1">Appraisals</span>
                                </Link>
                            </Menu.Item>)}
                        
                        {authUser.status >= 4 && (
                            <Menu.Item key="exits">
                                <Link to="/exits" className="flex mt-10">
                                    <div className="text-2xl -ml-1 mt-3">
                                        <FaDoorOpen/>
                                    </div>
                                    <span className="mx-5 py-1">Exit</span>
                                </Link>
                            </Menu.Item>)}
                        {authUser.status >= 4 && (

                            <Menu.Item key="leave-tracker">
                                <Link to="/leave-tracker" className="flex mt-10">
                                    <div className="text-2xl -ml-1 mt-3">
                                        <FaBriefcase/>
                                    </div>
                                    <span className="mx-5 py-1">Leave</span>
                                </Link>
                            </Menu.Item>)}

                        {authUser.status >= 4 && (

                            <Menu.Item key="paystubs">
                                <Link to="/paystubs" className="flex mt-10">
                                    <div className="text-2xl -ml-1 mt-3">
                                        <FaCheck/>
                                    </div>
                                    <span className="mx-5 py-1">Paystubs</span>
                                </Link>
                            </Menu.Item>)}
                        {authUser.status >= 4 && (

                            <Menu.Item key="query">
                                <Link to="/query" className="flex mt-10">
                                    <div className="text-2xl -ml-1 mt-3">
                                        <FaQuestionCircle/>
                                    </div>
                                    <span className="mx-5 py-1">Query</span>
                                </Link>
                            </Menu.Item>)}


                        {authUser.status >= 4 && (

                            <Menu.Item key="training">
                                <Link to="/training" className="flex mt-10">
                                    <div className="text-2xl -ml-1 mt-3">
                                        <FaBookReader/>
                                    </div>
                                    <span className="mx-5 py-1">Training</span>
                                </Link>
                            </Menu.Item>
                        )}
                        <Menu.Item key="guide">
                            <a download href="/User Guide - Self Service.pdf" className="flex">
                                <div className="text-2xl -ml-1 mt-3">
                                    <FaBook/>
                                </div>
                                <span className="mx-5 py-1">Guide</span>
                            </a>
                        </Menu.Item>

                    </MenuItemGroup>
                )}


            </Menu>
        </>
    );
};

SidebarContent.propTypes = {};
export default SidebarContent;

